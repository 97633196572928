
<div class="separator-block"></div>

<div class="search-field" *ngIf="searchable">


  <app-icon iconType="FILTER" iconSize="EXTRA_SMALL"></app-icon>

  <app-input
    [placeholder]="'dropdown.searchFieldPlaceholder' | cxTranslate"
    [labelless]="true"
    iconType="clear"
    (debounce)="search($event)"
  ></app-input>

</div>

<ng-container *ngIf="multi && showMassOperations">

  <div class="pseudo-link-buttons">
    <div class="all-link-button" (activateElement)="selectAll()">
      <a href="javascript:undefined;">{{ 'dropdown.all' | cxTranslate }}</a>
    </div>
    <div class="none-link-button" (activateElement)="selectNone()">
      <a href="javascript:undefined;">{{ 'dropdown.none' | cxTranslate }}</a>
    </div>
  </div>

</ng-container>

<div class="checkbox-group-container">

  <app-checkbox-group
    [dataSource]="getComponentDataSource()"
    display="column"
    [isRadioButtonGroup]="!multi"
    [hideFromTabIndex]="!multi"
    [hideBox]="!multi"
  ></app-checkbox-group>

</div>

<ng-container *ngIf="multi">

  <app-button
    [label]="'dropdown.apply' | cxTranslate"
    class="apply-button"
    [colorTheme]="['ACCENT']"
    buttonSize="SMALL"
    (activateElement)="apply()"
  ></app-button>

</ng-container>
