import { Component, ElementRef, EventEmitter, HostListener, Injector, OnInit } from '@angular/core';
import { CheckboxGroupDataSource, CheckboxItem } from '../../../checkbox-button-group/checkbox-group-data-source';
import { BasicMenuInsertComponent } from '../../../menu/basic-menu-insert.component';
import { DropdownData } from '../../dropdown-data-source.class';

export interface SingleDropdownItemsInsertData {
  dropdownData: DropdownData;
  checkboxGroupDataSource: CheckboxGroupDataSource;
  applyEmitter: EventEmitter<void>;
  actionEmitter: EventEmitter<string>;
}

@Component({
  selector: 'app-single-dropdown-items-insert',
  templateUrl: './single-dropdown-items-insert.component.html',
  styleUrls: ['./single-dropdown-items-insert.component.scss']
})
export class SingleDropdownItemsInsertComponent extends BasicMenuInsertComponent<SingleDropdownItemsInsertData> implements OnInit {

  /**
   * NOTE: not the focus candidate that is highlighted by pressing the arrow key but by typing text in the input field
   */
  private focusCandidate: HTMLElement;

  get checkboxGroupDataSource(): CheckboxGroupDataSource {
    return this.injectedData?.checkboxGroupDataSource;
  }

  get multi(): boolean {
    return this.injectedData?.dropdownData?.multi;
  }

  get showMassOperations(): boolean {
    return this.injectedData?.dropdownData?.showMassOperations;
  }

  get searchable(): boolean {
    return this.injectedData?.dropdownData?.searchable;
  }

  noItemsDataSource: CheckboxGroupDataSource;

  constructor(injector: Injector, private elementRef: ElementRef) {
    super(injector);

    const uniqueNoItemsLabelTranslationKey = this.injectedData.dropdownData.noItemsLabelTranslationKey;

    this.noItemsDataSource = new CheckboxGroupDataSource([
      {
        labelTranslationKey: (uniqueNoItemsLabelTranslationKey || 'dropdown.noItems'),
        selected: false,
        value: 'noItem',
        disabled: true
      }
    ]);
  }

  ngOnInit(): void {
    this.injectedData.actionEmitter?.subscribe(actionCommand => {
      if (actionCommand?.startsWith('scroll')) {
        const i = actionCommand.indexOf(':');
        if (i >= 0) {
          const label = actionCommand.slice(i + 1);
          this.scrollToCheckboxWith(label);
        }
      }
    });
  }

  getComponentDataSource(): CheckboxGroupDataSource {
    if (this.checkboxGroupDataSource?.items?.length) {
      return this.checkboxGroupDataSource;
    } else {
      return this.noItemsDataSource;
    }
  }

  search(term: string) {
    this.checkboxGroupDataSource?.search(term);
  }

  apply() {
    this.injectedData?.applyEmitter.emit();
  }

  selectAll() {
    this.injectedData?.checkboxGroupDataSource?.items?.forEach(item => item.selected = true);
    this.injectedData?.checkboxGroupDataSource?.triggerMarkForChange();
  }

  selectNone() {
    this.injectedData?.checkboxGroupDataSource?.items?.forEach(item => item.selected = false);
    this.injectedData?.checkboxGroupDataSource?.triggerMarkForChange();
  }

  scrollToCheckboxWith(label: string) {

    label = label.toLocaleLowerCase();

    const host = this.elementRef.nativeElement as HTMLElement;
    const scrollableBlock = host?.querySelector('.checkboxes.checkboxes-display-column');
    const scrollableBlock2 = host?.parentElement?.parentElement;
    const type = this.injectedData?.dropdownData?.itemValueType || 'string';

    // if no label - scroll to top
    if (!label) {
      scrollableBlock2?.scrollTo({top: 0});
      return;
    }

    const checkboxElements = Array.from(scrollableBlock?.children || []).map(el => {
      return {
        element: el,
        label: (el.children.item(0) as HTMLLabelElement)?.innerText.toLowerCase() || ''
      };
    });

    let target: {element: Element;label: string;};

    if (type === 'string') {
      target = checkboxElements.find(chEl => chEl.label === label);
    }

    let labelNum: number;
    if (type === 'numberLike') {
      labelNum = parseFloat(label);
    }

    target = checkboxElements.find((chEl, i) => {

      // last element is found if it has not been found before
      if (i === checkboxElements.length - 1) {
        return true;
      }

      if (type === 'string') {
        const text = chEl.label || '';
        return text.startsWith(label);
      }

      const itemNum = parseFloat((chEl.label || '0'));
      return itemNum >= labelNum;

    });


    target?.element?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});

    if (this.focusCandidate) {
      this.focusCandidate.classList.remove('app-dropdown-focus-canditate');
      this.focusCandidate = null;
    }

    // only focus canditate if the label matches the element text exactly - like wanted in COM-227
    if ((target?.element as HTMLElement)?.innerText === label) {
      target.element.classList.add('app-dropdown-focus-canditate');
      this.focusCandidate = target.element as HTMLElement;
    }

  }

  @HostListener('keydown.escape', ['$event'])
  private pressedEscape(e: KeyboardEvent) {
    if (this.menuRef?.isOpen) {
      this.menuRef.close();
      e.preventDefault();
      e.stopPropagation();
    }
  }

}
