import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { filter, map, skip, take } from 'rxjs/operators';
import { CheckboxGroupDataSource, CheckboxItem } from '../checkbox-button-group/checkbox-group-data-source';
import { CheckboxCollectionRenderingData } from '../checkbox/checkbox-renderings/checkbox-default-rendering.types';
import { InputRenderingData } from '../input/input-rendering.types';
import { MenuComponent, MenuComponentOptions, MenuInsert } from '../menu/menu.component';
import { SingleDropdownItemsInsertComponent, SingleDropdownItemsInsertData } from './inserts/single-dropdown-items-insert/single-dropdown-items-insert.component';

export interface DropdownItem<T = any> {
  value: string;
  label?: string;
  labelTranslationKey?: string;
  selected?: boolean;
  injectedRenderingData?: T;
  isFreeText?: boolean;
}


export interface DropdownData<T = any> {
  multi?: boolean;
  /**
   * renders mass operations for selecting in the menu
   */
  showMassOperations?: boolean;
  /**
   * with a search field in the menu
   */
  searchable?: boolean;
  /**
   * menu claims space in the DOM
   */
  claimSpace?: boolean;
  /**
   * menu is scrollable if more items exists than the value
   */
  maxVisibleItems?: number;
  /**
   * user is able to type into the input field
   */
  allowUserInput?: boolean;
  /**
   * user inputs are always strings and quite often not further useable for example: for sorting or calculating.
   * Use this function to get useable value from the user input (label)
   */
  freeTextToValueFunction?: (label: string) => any;
  /**
   * before an item of free text is created and further processed, this function offers a chance to update the item
   */
  freeTextItemUpdateFunction?: (item: CheckboxItem<T, any>) => (CheckboxItem<T, any>);
  /**
   * allow user to input free text. Otherwise it will be cleared on blur.
   */
  allowFreeText?: 'always' | 'never' | 'function';
  /**
   * a function, which is called if "allowFreeText" is "function". It decides if the inputed text becomes selected
   */
  allowFunction?: (item: DropdownItem<T>) => (boolean | Observable<boolean>)
  /**
   * what impact has the user input on the items: 'filter' the items or 'scroll' to the first item that matches the input
   */
  userInputImpactOnItems?: 'scroll' | 'filter';
  /**
   * a function, which decided if the current input targets an item or not - used if input filters possible results
   */
  doesUserInputFindItemFunction?: (term: string, item: CheckboxItem<any, any>) => boolean;
  /**
   * function, which is triggered after the user input
   */
  userInputDecisionFunction?: (text: string, dropdownDataSource: DropdownDataSource, internalDataSource: CheckboxGroupDataSource, inputRef?: HTMLInputElement) => void;
  /**
   * how to treat the value of the items
   */
  itemValueType?: 'string' | 'numberLike';
  /**
   * unique NoItemsLabelTranslationKey
   */
  noItemsLabelTranslationKey?: string;
}

export type DropdownDataSourceRole = 'dropdown:single' | 'dropdown:multi' | 'autocomplete:strict' | 'autocomplete:free';

export class DropdownDataSource<T = any> {

  static createEmptyItem<T>(ref?: Omit<DropdownItem<T>, 'label'>): DropdownItem<T> {
    // eslint-disable-next-line no-magic-numbers
    return {value: '', ...ref, label: '' };
  }

  /**
   * creates a dropdown datasource of a certain role but also supports an opportunity to override the data if necessary
   * -- Roles:
   *
   * "dropdown:single":
   * "dropdown:multi":
   * "autocomplete:strict":
   * "autocomplete:free":
   */
  static create<T = any>(role: DropdownDataSourceRole, overrideData: Partial<DropdownData<T>>, items?: DropdownItem<T>[], checkboxRenderingData?: CheckboxCollectionRenderingData, inputRenderingData?: InputRenderingData) {

    let data: DropdownData<T>;

    if (role === 'dropdown:single') {
      data = {allowUserInput: false, multi: false, maxVisibleItems: 5, searchable: false};
    }

    if (role === 'dropdown:multi') {
      data = {allowUserInput: false, multi: true, maxVisibleItems: 5, searchable: false};
    }

    if (role === 'autocomplete:strict') {
      data = {allowUserInput: true, allowFreeText: 'never', userInputDecisionFunction: () => {}, userInputImpactOnItems: 'filter', multi: false, maxVisibleItems: 5, searchable: false};
    }

    if (role === 'autocomplete:free') {
      data = {allowUserInput: true, allowFreeText: 'always', userInputDecisionFunction: () => {}, userInputImpactOnItems: 'filter', multi: false, maxVisibleItems: 5, searchable: false};
    }

    data = Object.assign(data, (overrideData || {}));

    const ds = new DropdownDataSource<T>(data, items, checkboxRenderingData, inputRenderingData);

    return ds;
  }

  private updateMenuCmpOptionsSubs: Subscription;

  private _dataBehaviorSubject = new BehaviorSubject<DropdownData>(null);
  private _menuComponentOptionsBehaviorSubject = new BehaviorSubject<MenuComponentOptions>(null);
  private _selectedItemsBehaviorSubject = new BehaviorSubject<DropdownItem[]>([]);
  private _inputRenderingDataBehaviorSubject = new BehaviorSubject<InputRenderingData>(null);
  private _selectedItemsValueSet = new Set<string>();
  private _newItemsSubscription: Subscription;

  actionEmitter = new EventEmitter<string>();
  inputFocusTrigger = new BehaviorSubject<boolean>(false);

  // is set by the component
  dropdownMenuRef$: Observable<MenuComponent>;

  get selectedItems$(): Observable<DropdownItem<T>[]> {
    return this._selectedItemsBehaviorSubject.asObservable();
  }

  get selectedItems(): DropdownItem<T>[] {
    return this._selectedItemsBehaviorSubject.value;
  }

  get inputRenderingData(): InputRenderingData {
    return this._inputRenderingDataBehaviorSubject.value;
  }

  set inputRenderingData(value: InputRenderingData) {
    if(value?.data) {
      value.data = {...(value.data), getDropdownMenuRefObservable: () => this.dropdownMenuRef$}; // fulfill DropdownInputDefaultRenderingData and ConfigBeltInputDefaultRenderingData as well
    }
    this._inputRenderingDataBehaviorSubject.next(value);
  }

  get menuComponentOptions$(): Observable<MenuComponentOptions<SingleDropdownItemsInsertData>> {
    return this._menuComponentOptionsBehaviorSubject.asObservable();
  }

  get menuComponentOptions(): MenuComponentOptions<SingleDropdownItemsInsertData> {
    return this._menuComponentOptionsBehaviorSubject.value;
  }

  get data(): DropdownData {
    return this._dataBehaviorSubject.value;
  }

  set data(value: DropdownData) {
    this._dataBehaviorSubject.next(value);
  }

  private _internalCheckboxGroupDataSource: CheckboxGroupDataSource;

  get internalCheckboxGroupDataSource(): CheckboxGroupDataSource {
    return this._internalCheckboxGroupDataSource;
  }

  set internalCheckboxGroupDataSource(value: CheckboxGroupDataSource) {
    this._internalCheckboxGroupDataSource = value;
  }

  constructor(data: DropdownData, items?: DropdownItem<T>[], checkboxRenderingData?: CheckboxCollectionRenderingData, inputRenderingData?: InputRenderingData) {
    if (data) {
      this.data = data;
      this.setItems(items || []);

      this.menuComponentOptions$.pipe(filter(_ => !!_), take(1)).subscribe(menuComponentOptions => {
        const cbDataSource = menuComponentOptions.inserts[0].data.checkboxGroupDataSource;
        cbDataSource.renderingData = checkboxRenderingData;
      });

    }
    if (inputRenderingData) {
      this.inputRenderingData = inputRenderingData;
    }
  }

  free() {
    this.dropdownMenuRef$ = null;
    this.updateMenuCmpOptionsSubs?.unsubscribe();
    this._newItemsSubscription?.unsubscribe();
  }

  closeMenu() {
    this.syncSelectedItems();
    this.dropdownMenuRef$?.pipe(filter(_ => !!_), take(1)).subscribe(ds => ds.close());
  }


  updateView() {
    this.setItems(this.getItems());
    this.menuComponentOptions?.inserts?.[0]?.data?.applyEmitter?.emit();
  }

  triggerMarkForChange(withMenu = false) {
    this.menuComponentOptions?.inserts?.forEach(insert => {
      insert.data.checkboxGroupDataSource.triggerMarkForChange();
    });

    if (withMenu) {
      this.dropdownMenuRef$?.pipe(filter(_ => !!_), take(1)).subscribe(ref => {
        ref?.triggerMarkForChange();
      });
    }

  }

  makeDecisionOnUserInput(text: string, inputRef?: HTMLInputElement) {

    const hit = this.getItems().find(item => item.label === text);
    const cbDataSource = this.internalCheckboxGroupDataSource;

    if (this.data.userInputDecisionFunction) {

      this.data.userInputDecisionFunction(text, this, cbDataSource, inputRef);

      return;
    }

    if (hit) {
      if (!hit.selected) {
        cbDataSource.toggleItem(hit);
        this.triggerMarkForChange(true);
      }
    } else {
      if (inputRef) {
        inputRef.value = '';
        this.deselectAll();
        this.resetSearch();
      }
    }

  }

  setItems(items: DropdownItem<T>[], checkboxRenderingData?: CheckboxCollectionRenderingData) {
    if (this.menuComponentOptions) {

      const cbitems: CheckboxItem<string>[] = items?.map<CheckboxItem<string>>(dropdownItem => (dropdownItem));

      const cbDataSource = this.menuComponentOptions.inserts[0].data.checkboxGroupDataSource;
      if (checkboxRenderingData) {
        cbDataSource.renderingData = checkboxRenderingData;
      }
      cbDataSource.items = cbitems;
      cbDataSource.triggerMarkForChange();

    } else {
      this.updateMenuComponentOptions(items);
    }
  }

  getItems(): DropdownItem<T>[] {
    return this.internalCheckboxGroupDataSource?.items as DropdownItem[];
  }

  /**
   * tries to select an item with a matching value and returns if it was successful
   */
  getItemWithAttribute(attr: 'value' | 'label', value: string) {
    const internalItem = this.internalCheckboxGroupDataSource.items.find(item => item[attr] === value);
    return internalItem;
  }

  getPossibleItemsThatInputFinds(term: string) {

    const defaultSearchFunction = (term: string, item: CheckboxItem): boolean => {
      return item?.label?.toLowerCase()?.includes(term?.toLowerCase());
    };

    const searchFn = this.data.doesUserInputFindItemFunction || defaultSearchFunction;

    const foundItems = this.internalCheckboxGroupDataSource.items.filter(item => {
      if (item.hidden) {
        return false;
      } else {
        return searchFn(term, item);
      }
    });

    return foundItems;
  }

  search(text: string, searchFunction?: (term: string, item: CheckboxItem<any, any>) => boolean) {
    const fn = this.data.doesUserInputFindItemFunction || searchFunction;
    this.internalCheckboxGroupDataSource?.search(text, fn);
  }

  resetSearch() {
    this.internalCheckboxGroupDataSource?.resetSearch();
  }

  selectFreeTextItem(fti: CheckboxItem<any, any>): Observable<boolean> {

    const subj = new Subject<boolean>();

    const item: DropdownItem<T> = fti as DropdownItem<T>;

    const addFn = (isAllowed: boolean, allowedItem: DropdownItem<T>) => {
      if (!isAllowed) {
        return false;
      }
      allowedItem.selected = true;
      if (this.data.multi) {
        const tmp = this._selectedItemsBehaviorSubject.value || [];
        tmp.push(allowedItem);
        this._selectedItemsBehaviorSubject.next(tmp);
      } else {
        this._selectedItemsBehaviorSubject.next([allowedItem]);
      }
      return true;
    };

    if (this.data.allowFreeText === 'always') {
      subj.complete();
      return of(addFn(true, item));
    }

    if (this.data.allowFreeText === 'function') {
      const res = this.data.allowFunction(item);

      if (res instanceof Observable) {
        res.pipe(take(1)).subscribe(obsRes => {
          subj.next(addFn(obsRes, item));
          subj.complete();
        });
      } else {
        subj.complete();
        return of(addFn(res, item));
      }

    }

    return subj;
  }

  /**
   * tries to select an item with a matching value and returns if it was successful
   */
  selectItemWithAttribute(attr: 'value' | 'label', value: string, withSelectionChange?: boolean) {
    const internalItem = this.getItemWithAttribute(attr, value);
    this.selectInternalItem(internalItem, withSelectionChange);
    return internalItem;
  }

  /**
   * tries to select an item with a matching value and returns if it was successful
   */
  selectItemWithInjectedRenderingData(injData: any, withSelectionChange?: boolean) {
    const internalItem = this.internalCheckboxGroupDataSource.items.find(item => item.injectedRenderingData === injData);
    this.selectInternalItem(internalItem, withSelectionChange);
    return internalItem;
  }

  private selectInternalItem(internalItem: CheckboxItem, withSelectionChange?: boolean) {
    if (internalItem) {
      this.internalCheckboxGroupDataSource.selectItem(internalItem);

      if (withSelectionChange) {
        this.menuComponentOptions$.pipe(filter(_ => !!_), take(1)).subscribe(opt => opt.inserts[0].data.applyEmitter.emit());
      }

      this.internalCheckboxGroupDataSource?.triggerMarkForChange();
    }
  }

  selectAll(withSelectionChange?: boolean) {

    if (this.data.multi) {
      this.internalCheckboxGroupDataSource?.selectAll();
    }

    if (withSelectionChange) {
      this.menuComponentOptions$.pipe(filter(_ => !!_), take(1)).subscribe(opt => opt.inserts[0].data.applyEmitter.emit());
    }

    this.internalCheckboxGroupDataSource?.triggerMarkForChange();
  }

  deselectAll(withSelectionChange?: boolean) {

    this.internalCheckboxGroupDataSource?.deselectAll();

    if (withSelectionChange) {
      this.menuComponentOptions$.pipe(filter(_ => !!_), take(1)).subscribe(opt => opt.inserts[0].data.applyEmitter.emit());
    }

    this.internalCheckboxGroupDataSource?.triggerMarkForChange();
  }

  /**
   * reverts a number of steps if it is possible - returns true if it was possible
   */
  resetItemSelectionHistory(stepBackwards = 1): boolean {
    const succesful = this.internalCheckboxGroupDataSource.resetItemSelectionHistory(stepBackwards);
    if (succesful) {
      this.updateView();
      this.triggerMarkForChange();
    }
    return succesful;
  }

  resetUntilNextSelection(): boolean {
    const succesful = this.internalCheckboxGroupDataSource.resetUntilNextSelection();
    if (succesful) {
      this.updateView();
      this.triggerMarkForChange();
    }
    return succesful;
  }

  getMenuMaxHeight = () => { return null; };

  private updateMenuComponentOptions(items?: DropdownItem<T>[]) {

    this._newItemsSubscription?.unsubscribe();
    this.updateMenuCmpOptionsSubs?.unsubscribe();
    this.updateMenuCmpOptionsSubs = new Subscription();

    const applyEmitter = new EventEmitter<void>();

    const opt: MenuComponentOptions<SingleDropdownItemsInsertData> = {
      inserts: [],
      menuCSSPosition: this.data.claimSpace ? 'relative' : 'absolute',
      verticalMenuOffset: -2,
      closesOnOutsideClick: true,
      menuCSSDisplay: this.data.claimSpace ? 'inline' : 'block',
      getMenuMaxHeight: () => { return this.getMenuMaxHeight(); },
    };

    this.internalCheckboxGroupDataSource = new CheckboxGroupDataSource(items);
    this.internalCheckboxGroupDataSource.isRadioButtonGroup = !this.data.multi;
    this._newItemsSubscription = this.internalCheckboxGroupDataSource.items$.subscribe(items => {
      // are there preselected items in the new items
      this.menuComponentOptions?.inserts?.[0]?.data?.applyEmitter?.emit();
    });

    // subscribe to the event if selection of checkbox group changes
    const sub1 = this.internalCheckboxGroupDataSource.selectedItems$.pipe(skip(1)).subscribe(selectedCheckboxItems => {
      if (!this.data.multi) {
        this._selectedItemsValueSet.clear();
        if (selectedCheckboxItems?.[0]?.value || typeof selectedCheckboxItems?.[0]?.value === 'string') {
          this._selectedItemsValueSet.add(selectedCheckboxItems[0].value);
        }
        this.closeMenu();
      }
    });

    const sub2 = applyEmitter.subscribe(() => {
      this._selectedItemsValueSet.clear();
      this.internalCheckboxGroupDataSource.items.forEach(item => {
        if (item.selected) {
          this._selectedItemsValueSet.add(item.value);
        }
      });

      this.closeMenu();
    });

    this.updateMenuCmpOptionsSubs.add(sub1);
    this.updateMenuCmpOptionsSubs.add(sub2);

    const menuInsert: MenuInsert = {
      data: {
        dropdownData: this.data,
        checkboxGroupDataSource: this.internalCheckboxGroupDataSource,
        applyEmitter,
        actionEmitter: this.actionEmitter
      },
      component: SingleDropdownItemsInsertComponent
    };

    opt.inserts.push(menuInsert);

    this._menuComponentOptionsBehaviorSubject.next(opt);

    applyEmitter.emit();
  }

  private syncSelectedItems() {
    const selectedItems = this.getItems().filter(item => {
      if (this._selectedItemsValueSet.has(item.value)) {
        item.selected = true;
        return true;
      } else {
        item.selected = false;
        return false;
      }
    });

    if (this.differFromSelectedItems(selectedItems)) {
      this._selectedItemsBehaviorSubject.next(selectedItems);
    }
  }

  private getFingerprintOfItems(thisItems?: DropdownItem[]): string {
    return (thisItems || this._selectedItemsBehaviorSubject.value)?.map<string>(item => 'v:' + item.value)?.sort()?.join(',');
  }

  private differFromSelectedItems(thisItems?: DropdownItem[]): boolean {
    const newFingerprint = this.getFingerprintOfItems(thisItems);
    const oldFingerprint = this.getFingerprintOfItems();
    return newFingerprint !== oldFingerprint;
  }

}
